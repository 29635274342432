
document.addEventListener("DOMContentLoaded", function() {
	const triggers = document.querySelectorAll(`.${ speedBumpTriggerClass }`);

	function openModal(templateContent, proceedUrl) {
		let modal = document.querySelector("#speedBumpModal");
		if (!modal) {
			modal = document.createElement("div");
			modal.id = "speedBumpModal";
			modal.className = "speedbump-modal";
			modal.innerHTML = `
				<div class="modal-content">
					<span class="close-button">&times;</span>
					<div class="modal-body"></div>
					<div class="modal-footer">
						<button id="proceedButton" class="primary">${ speedBumpI18N.proceed }</button>
						<button id="cancelButton" class="secondary">${ speedBumpI18N.cancel }</button>
					</div>
				</div>
			`;

			document.body.appendChild(modal);
		}

		const destinationTag = document.createElement( "p" )
		destinationTag.textContent = `${ speedBumpI18N.destination }: ${ proceedUrl }`
		templateContent.appendChild( destinationTag )

		const modalBody = modal.querySelector(".modal-body");
		modalBody.innerHTML = "";
		modalBody.appendChild(templateContent);

		modal.style.display = "block";

		const closeButton = modal.querySelector(".close-button");
		closeButton.onclick = function() {
			modal.style.display = "none";
		};

		const proceedButton = modal.querySelector("#proceedButton");
		proceedButton.onclick = function() {
			window.open(proceedUrl, "_blank");
			modal.style.display = "none";
		};

		const cancelButton = modal.querySelector("#cancelButton");
		cancelButton.onclick = function() {
			modal.style.display = "none";
		};
	}

	triggers.forEach(trigger => {
		trigger.addEventListener("click", function(event) {
			event.preventDefault();
			const proceedUrl = this.href;
			const template = document.querySelector("#speed-bump-template");
			if (template && template.content) {
				const clonedContent = document.importNode(template.content, true);
				openModal(clonedContent, proceedUrl);
			}
		});
	});
});
